@import "../../../../components/common/styles";

.content-sticky-title {
    position: sticky;
    width: 100%;

    font-family: $font-family;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.1em;
    color: #000000;
    height: 36px;
    background-color: white;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    // margin-top: -10px;
    z-index: 1;
    padding-left: 10px;

    &_first {
        top: -10px;
        margin-top: -10px;
    }
    
    &_second {
        top: 26px;
    }

    &_first-with-header-search {
        top: 89px;
    }

    &_second-with-header-search {
        top: 125px;
    }
}

.ancestors {
    overflow: hidden;
    text-overflow: ellipsis;
}

.content-footer {
    height: 50px;
    width: 100%;
    position: sticky;
    bottom: 0;
    margin-left: -10px;
    width: calc(100% + 20px);
    bottom: -10px;
    margin-bottom: -10px;
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid rgba(0, 0, 0, 0.15);
    background-color: white;
    padding: 0 10px;
    z-index: 10;

    :global(button.primary-simple) {
        padding: 0;

        :global(.bp4-button-text) {
            text-align: left;
        }
    }
}
