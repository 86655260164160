@import "../../components/common/styles";

.mpd-select-item.bp4-skeleton {
    max-height: 30px;
    border-radius: 6px;
    margin-bottom: 10px;
    width: 100%;
}

.mpd-select-item {
    &.temp-state {
        background-color: rgba(31, 118, 255, 0.1);
        border: 1px dashed #1f76ff;
    }
    display: flex;
    background-color: white;
    align-items: center;
    cursor: pointer;
    border-radius: 6px;
    padding: 0 10px;
    justify-content: space-between;

    font-family: $font-family;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.02em;
    color: rgba(0, 0, 0, 0.75);
    height: 40px;
    box-sizing: border-box;
    overflow: hidden;

    .styled-checkbox {
        padding: 0 !important;
    }

    .children-wrapper {
        display: flex;
        align-items: center;
    }

    &-spinner {
        .bp4-spinner-head {
            stroke: #1f76ff !important;
        }
        .bp4-spinner-track {
            stroke: rgba(92, 112, 128, 0.2) !important;
        }
    }

    .bp4-control-indicator {
        width: 24px !important;
        height: 24px !important;
        margin: 0 !important;
        &::before {
            width: 24px !important;
            height: 24px !important;
        }
    }

    .left-wrapper {
        display: flex;
        column-gap: 10px;
        align-items: center;
        height: 24px;

        flex-grow: 1;
        overflow-x: hidden;
        max-width: 100%;

        height: 100%;
        line-height: 20px;

        span {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow-x: hidden;
        }
    }
}

.mpd-select-item.dragging,
.mpd-select-item:hover {
    background-color: #1f76ff;
    color: white;

    .bp4-spinner-head {
        stroke: white !important;
    }

    .bp4-spinner-track {
        stroke: white;
        opacity: 0.15;
    }

    svg.mpd-icon.left-icon {
        path {
            stroke: white;
        }
    }

    svg.mpd-icon.burger-icon:hover {
        cursor: grab;
    }
    svg.mpd-icon.burger-icon path {
        fill: white !important;
        stroke: unset;
        fill-opacity: 1 !important;
    }

    .bp4-control-indicator {
        background-color: white !important;
        &::before {
            // width: 22px !important;
            // height: 22px !important;
        }
    }
}

.mpd-select-item.selected {
    .tick-icon {
        width: 13px;
        margin-left: auto;
        path {
            stroke: #1f76ff;
        }
    }

    svg.left-icon {
        path {
            stroke: #1f76ff;
        }
    }
    color: #1f76ff;

    &:hover {
        color: white;
        .tick-icon {
            path {
                fill: #1f76ff;
                stroke: white !important;
            }
        }
    }
}

.mpd-select-item.bp4-disabled {
    cursor: not-allowed;
    background-color: white;
    opacity: 0.5;

    .bp4-disabled.styled-checkbox input:checked ~ .bp4-control-indicator {
        opacity: 1;
    }
}

.mpd-select-item-label {
    height: 40px;
    span.bp4-control-indicator {
        margin-top: 8px !important;
    }
}

.mpd-select-item.delete {
    &:hover {
        background-color: #f23a50;
        color: white;
    }

    color: #f23a50;
}

.mpd-select-item.item-to-remove {
    &.temp-state {
        background-color: rgba(242, 58, 80, 0.1);
        border: 1px dashed #f23a50;
    }

    &.temp-state:hover,
    &:hover {
        background-color: #f23a50;
    }

    .styled-checkbox input:checked ~ .#{$bpns}-control-indicator {
        background-color: #f23a50 !important;
    }
}
