@import "../common/styles";

.tag-main-wrapper {
    display: flex;
    background: $primary-color;
    border-radius: 20px;
    box-sizing: border-box;
    height: max-content;
    color: white;
    width: max-content;
    align-items: center;
    height: 24px;

    &_with-tooltip {
        max-width: 100px;

        .tag-label {
            text-overflow: ellipsis;
            overflow-x: hidden;
        }
    }

    &.counter {
        text-align: center;
        height: 24px;
        min-width: 24px;
        border-radius: 24px;
        .tag-label {
            padding: 0 2px;
            width: 100%;
        }
    }

    .tag-label {
        font-family: $font-family;
        font-style: normal;
        font-weight: 600;
        font-size: 10px;
        // line-height: 100%;
        white-space: nowrap;
        padding-left: 10px;
        padding-right: 10px;
        // padding-left: 10px;
        // padding-right: 6px;
    }

    .remove-button {
        background: none;
        color: inherit;
        border: none;
        padding: 0;
        font: inherit;
        cursor: pointer;
        outline: inherit;
        // background-color: #ffffff;
        border-radius: 100%;
        width: 14px;
        height: 14px;
        margin-right: 5px;
        display: flex;
    }
}

.list-main-wrapper {
    display: flex;
    background: rgba(31, 118, 255, 0.1);
    border-radius: 4px;
    align-items: center;
    height: 21px;

    .tag-label {
        font-family: $font-family;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.02em;
        color: $primary-color;
        white-space: nowrap;
        padding-left: 10px;
        padding-right: 10px;
    }

    .remove-button {
        background: none;
        color: inherit;
        border: none;
        padding: 0;
        font: inherit;
        cursor: pointer;
        outline: inherit;
        margin-right: 8px;
        // background-color: #ffffff;
        border-radius: 100%;
        width: 14px;
        height: 14px;
        display: flex;

        svg path {
            fill: $primary-color;
        }
    }
}
