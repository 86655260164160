@import "../../../../common/styles";

.contacts-list-header-label {
    font-family: $font-family;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.1em;
    color: #000000;
    white-space: nowrap;
}

.contacts-list-header,
.contacts-list-item {
    display: grid;
    padding: 0 20px;
    column-gap: 20px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    height: 60px;
    align-items: center;
    min-width: 1080px;
}

.contacts-list-header {
    position: sticky;
    top: 0;
    background-color: #fafafa;
    z-index: 1;
}

.contacts-list-header,
.contacts-list-item {
    // { refactor width height
    .checkbox {
        width: 24px;
        height: 24px;
        :global(.bp4-control-indicator) {
            min-width: 24px;
            height: 24px;
            display: flex;
            align-items: center;
            justify-content: center;

            &:before {
                height: 24px;
                width: 24px;
            }
        }
    }
    // refactor width height }
}

.contacts-list-item {
    &:hover {
        background-color: #f4f8ff;
        cursor: pointer;
    }

    &_hide-border {
        border: 3px solid red;
    }

    &-chart-wrapper,
    &-last-contacted,
    &-more-dropdown-wrapper {
        display: flex;
        justify-content: flex-start;
    }

    &-more-dropdown-wrapper {
        position: sticky;
        right: 20px;

        :global(.select-main-wrapper) {
            display: flex;
            align-items: center;
            justify-content: flex-end;
        }
    }

    &-name {
        font-family: $font-family;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        color: #000000;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    &-last-contacted {
        font-family: $font-family;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
        color: rgba(0, 0, 0, 0.75);
    }

    &-channels-wrapper {
        display: flex;
        column-gap: 10px;
        flex-wrap: nowrap;
        min-height: 16px;
    }

    :global(.bp4-skeleton) {
        // max-height: 16px;
        height: 16px;
        border-radius: 8px;
        min-width: calc(100% - 20px);
    }

    .tags-wrapper {
        display: flex;
        column-gap: 5px;
        overflow: auto;
    }
}

.contacts-list-item_header {
    background-color: #fafafa;
}

.tags-tooltip-list {
    display: flex;
    flex-direction: column;
    max-height: 200px;
    overflow: auto;
    row-gap: 5px;
}
