@import "../common/styles";

.#{$bpns}-navbar.styled-toolbar {
  height: 50px;
  flex-shrink: 0;
  background-color: white;
  box-shadow: none !important;
  border-bottom: 1px solid $border-color;
  white-space: nowrap;
  display: flex;
  justify-content: space-between;
  min-width: initial;
  width: 100%;
  position: initial !important;
  padding: 0 20px;
  &.mpd-loading {
    pointer-events: none;
  }
  .mpd-align-center {
    display: flex;
    justify-content: center;
    z-index: -1;
  }

  .navbar-right-wrapper {
    display: flex;
    column-gap: 10px;
  }
}

@include for-size(phone-only) {
  .styled-toolbar {
    &.with-padding {
      padding-left: $small-block-padding !important;
      padding-right: $small-block-padding !important;
    }

    .bread-crumbs-button {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
  }
}

@include for-size(tablet-portrait-up) {
  .styled-toolbar {
    &.with-padding {
      padding-left: $small-padding !important;
      padding-right: $small-padding !important;
    }
  }
}

@include for-size(tablet-landscape-up) {
  .styled-toolbar {
    &.with-padding {
      padding-left: $small-padding !important;
      padding-right: $small-padding !important;
    }
  }
}
